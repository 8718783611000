import fetch from 'auth/FetchInterceptor'

const veiculoService = {}

veiculoService.all = function () {
  return fetch({
    url: '/veiculo/all',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

veiculoService.id = function (data) {
  return fetch({
    url: `/veiculo/${data}/id`,
    method: 'get',
    data: data
  })
}

veiculoService.save = function (data) {
  return fetch({
    url: '/veiculo',
    method: 'post',
    data: data
  })
}

export default veiculoService