import fetch from 'auth/FetchInterceptor'

const contratoService = {}

contratoService.all = function () {
  return fetch({
    url: '/contrato/all',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

contratoService.getTestemunha = function () {
  return fetch({
    url: '/testemunha',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

contratoService.saveTestemunha = function (data) {
  return fetch({
    url: '/testemunha',
    method: 'post',
    data: data
  })
}

contratoService.id = function (data) {
  return fetch({
    url: `/contrato/${data}/id`,
    method: 'get',
    data: data
  })
}

contratoService.save = function (data) {
  return fetch({
    url: '/contrato',
    method: 'post',
    data: data
  })
}

contratoService.inativar = function (data) {
  return fetch({
    url: `/contrato/${data}/inativar`,
    method: 'put',
    data: data
  })
}

contratoService.criarBoleto = function (data) {
  return fetch({
    url: `/contrato/${data}/criar-boleto`,
    method: 'post',
    data: data
  })
}

contratoService.criarJuridico = function (data) {
  return fetch({
    url: `/contrato/${data}/criar-contrato-juridico`,
    method: 'post',
    data: data
  })
}

export default contratoService