import fetch from 'auth/FetchInterceptor'

const imagemService = {}

imagemService.upload = function (formData) {
  return fetch({
    url: '/imagem/upload',
    method: 'post',
    body: formData,
  })
}

imagemService.renderizar = function (data) {
  return fetch({
    url: '/imagem/renderizar',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

imagemService.renderizarTexto = function (data) {
  return fetch({
    url: '/imagem/texto/renderizar',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

imagemService.obterImagem = function (id) {
  return fetch({
    url: `/imagem/${id}/obter`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}



export default imagemService