import fetch from 'auth/FetchInterceptor'

const usuarioService = {}

usuarioService.start = function () {
  return fetch({
    url: '/usuario/start',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ0aGlhZ29jbWFyY2VsbG8yQGdtYWlsLmNvbSIsImV4cCI6MTcwMDQ2ODI4NSwiaWF0IjoxNzAwNDUwMjg1fQ.VbcNE-m_1oNiUA54sOoXhIcE835s_0EhUVRsFjIkLUZeVPXcQWPIoMREHk_2C1QSP03Pr0_WgvnGRkj1MxMi2w'
      },
  })
}

usuarioService.conta = function () {
  return fetch({
    url: '/minha-conta',
    method: 'get',
  })
}

usuarioService.indicar = function (data) {
  return fetch({
    url: `/indicacao/${data}/criar`,
    method: 'post',
    data: data
  })
}

usuarioService.setPost = function (data) {
  return fetch({
    url: '/posts',
    method: 'post',
    data: data
  })
}

usuarioService.resetSenha = function (data) {
  return fetch({
    url: `/usuario/${data}/nova-senha`,
    method: 'post',
    data: data
  })
}

usuarioService.inativar = function (data) {
  return fetch({
    url: `/usuario/${data}/inativar`,
    method: 'post',
    data: data
  })
}

usuarioService.ativar = function (data) {
  return fetch({
    url: `/usuario/${data}/ativar`,
    method: 'post',
    data: data
  })
}
export default usuarioService