import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { PROFILE } from 'constants/StoreConstants';
import UsuarioService from 'services/UsuarioService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const getStart = createAsyncThunk('usuario/start', async (data, { rejectWithValue }) => {
	try {
		const response = await UsuarioService.start()
		localStorage.setItem(PROFILE, JSON.stringify(response));
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getConta = createAsyncThunk('minha-conta', async () => {
	const response = await UsuarioService.conta()
	return response;
})

export const indicar = createAsyncThunk('indicar', async (data, { rejectWithValue }) => {
	try {
		const response = await UsuarioService.indicar(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const resetSenha = createAsyncThunk('usuario/resetSenha', async (data, { rejectWithValue }) => {
	try {
		const response = await UsuarioService.resetSenha(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar usuário')
	}
})

export const inativar = createAsyncThunk('usuario/inativar', async (data, { rejectWithValue }) => {
	try {
		const response = await UsuarioService.inativar(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao inativar usuário')
	}
})

export const ativar = createAsyncThunk('usuario/ativar', async (data, { rejectWithValue }) => {
	try {
		const response = await UsuarioService.ativar(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao ativar usuário')
	}
})

export const usuarioSlice = createSlice({
	name: 'usuario',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getConta.pending, (state) => {
				state.loading = true
			})
			.addCase(getConta.fulfilled, (state, action) => {
				state.loading = false
				state.conta = action.payload
			})
			.addCase(getConta.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = usuarioSlice.actions

export default usuarioSlice.reducer