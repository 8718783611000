import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import ImagemService from 'services/ImagemService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}


export const upload = createAsyncThunk('imagem/upload', async (formData, { rejectWithValue }) => {
	try {
		const response = await ImagemService.upload(formData);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao enviar imagem')
	}
})

export const renderizar = createAsyncThunk('imagem/renderizar', async (data, { rejectWithValue }) => {
	try {
		const response = await ImagemService.renderizar(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao tentar renderizar imagem')
	}
})

export const renderizarTexto = createAsyncThunk('imagem/texto', async (data, { rejectWithValue }) => {
	try {
		const response = await ImagemService.renderizarTexto(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao tentar renderizar imagem')
	}
})

export const obter = createAsyncThunk('imagem/obter', async (id, { rejectWithValue }) => {
	const response = await ImagemService.obterImagem(id)
	return response;
})

export const imagemSlice = createSlice({
	name: 'imagem',
	initialState,
	reducers: {
		clearContratoEdicao: (state) => {
			state.edicao = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(upload.pending, (state) => {
				state.loading = true
			})
			.addCase(upload.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/'
				state.contratos = action.payload
			})
			.addCase(upload.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(renderizar.pending, (state) => {
				state.loading = true
			})
			.addCase(renderizar.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/'
				state.contratos = action.payload
			})
			.addCase(renderizar.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	showLoading,
} = imagemSlice.actions

export default imagemSlice.reducer