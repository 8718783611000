import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'inicio.default',
        path: `${APP_PREFIX_PATH}/inicio/default`,
        component: React.lazy(() => import('views/app-views/inicio/default')),
    },
    {
        key: 'interior.default',
        path: `${APP_PREFIX_PATH}/interior/default`,
        component: React.lazy(() => import('views/app-views/interior/default')),
    },
    {
        key: 'indique.default',
        path: `${APP_PREFIX_PATH}/indique/default`,
        component: React.lazy(() => import('views/app-views/indique/default')),
    },
    {
        key: 'conta.default',
        path: `${APP_PREFIX_PATH}/conta/default`,
        component: React.lazy(() => import('views/app-views/conta/default')),
    },
]